<template>
    <div id="manage-alert-page">
        <toolbar-back-button-component :title="$t('manage_alert_page.manage_alert_of_asset', {name: tank.name})"/>

        <div class="pa-4">
            <p class="font-color-medium mb-0 font-12">
                {{ $t('manage_alert_page.list_alerts_setup') }}
            </p>

            <p class="font-color-medium font-12 mb-7">
                {{ $t('manage_alert_page.alerts_shared_with_other_users') }}
            </p>

            <!--
                        <v-row class="ma-0 mb-4 min-height-40">
                            <p class="font-12 my-auto text-inline-switch">Vos préférences de contact pour les alertes</p>

                            <v-row class="inline-switch mr-0">
                                <div>
                                    <p class="font-12 ml-1 mb-0">Badge</p>
                                    <v-switch
                                        v-model="switch_badge"
                                        class="mt-0"
                                        inset
                                        @click="switchAlert('notification_badge')"
                                    />
                                </div>

                                <div>
                                    <p class="font-12 ml-1 mb-0">Notifs</p>
                                    <v-switch
                                        v-model="switch_push"
                                        class="mt-0"
                                        inset
                                        @click="switchAlert('notification_push')"
                                    />
                                </div>

                                <div>
                                    <p class="font-12 ml-2 mb-0">SMS</p>
                                    <v-switch
                                        v-model="switch_sms"
                                        class="mt-0"
                                        inset
                                        @click="switchAlert('notification_sms')"
                                    />
                                </div>

                                <div>
                                    <p class="font-12 ml-1 mb-0">Email</p>
                                    <v-switch
                                        v-model="switch_email"
                                        class="mt-0"
                                        inset
                                        @click="switchAlert('notification_email')"
                                    />
                                </div>
                            </v-row>
                        </v-row>
            -->

            <v-text-field v-model="search_alert" :label="$t('global.search')"
                          class="white-text-field mb-2" filled hide-details prepend-inner-icon="mdi-magnify"
            />

            <template v-if="!loading_alerts">
                <template>
                    <p class="font-weight-bold mt-4 mb-2">
                        {{ $t('manage_alert_page.total_alerts', {total_alerts: alerts.length}) }}
                    </p>

                    <div v-for="(alert, index) in alerts" :key="index">
                        <alert-card-component :alert="alert" class="mb-2" v-on:manageDialogEdit="manageDialogEdit"/>
                    </div>
                </template>
            </template>

            <template v-else>
                <v-skeleton-loader class="mb-2" type="chip"/>
                <v-skeleton-loader v-for="n in 2" :key="n" class="mb-2" type="list-item-avatar, divider, actions"/>
            </template>

            <!--            <v-card elevation="1">
                            <v-card-title>
                                <span class="font-weight-bold font-16">Historique des alertes déclenchées</span>
                            </v-card-title>

                            <v-card-text>
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    dense
                                    hide-details
                                    label="Rechercher une alerte"
                                    outlined
                                    single-line
                                    class="white-text-field"
                                />

                                <v-data-table
                                    :headers="headers"
                                    :items="actions"
                                    :items-per-page="nb_item_show"
                                    :loading="loading_actions"
                                    :search="search"
                                    class="low-height-datatable"
                                    disable-sort
                                    hide-default-footer
                                >
                                    <template v-slot:item.last_activation="{ item }">
                                        <chips-last-statement
                                            :danger="345600"
                                            :date="item.last_activation"
                                            :warning="172800"
                                            class="ml-1 mb-2"
                                        />
                                    </template>

                                    <template v-slot:no-data>
                                        <span>Aucune donnée</span>
                                    </template>

                                    <template v-slot:no-results>
                                        <span>Aucune donnée ne correspond à votre recherche</span>
                                    </template>

                                    <template v-slot:footer>
                                        <div v-if="actions.length > 0" class="text-center">
                                            <v-btn
                                                class="mx-auto"
                                                color="primary"
                                                text
                                                @click="nb_item_show = nb_item_show + 5"
                                            >
                                                <span class="primary&#45;&#45;text text-decoration-underline font-12">voir plus</span>
                                            </v-btn>
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>-->
        </div>

        <dialog-update-alert-component
            v-if="dialog_alert && userHasAccess('BASE', this.role_edit)"
            ref="DialogUpdateAlertComponent"
            :alert="dialog_alert"
            :is_new_alert="is_new_alert"
        />

        <v-btn class="add-alert-btn" color="secondary" elevation="0" fab @click="openDialogEditAlert(true)"
               v-if="userHasAccess('BASE', this.role_edit)"
        >
            <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>

        <div class="bottom-hidden-div"></div>
    </div>
</template>

<script>
import ToolbarBackButtonComponent from "@/components/Global/ToolbarBackButtonComponent";
import AlertCardComponent from "@/components/Alert/AlertCardComponent";
import DialogUpdateAlertComponent from "@/components/Alert/DialogUpdateAlertComponent";
import AlertRepository from "../../repositories/AlertRepository";
import DeviceRepository from "../../repositories/DeviceRepository";
import axios from "axios";

export default {
    name: 'ManageAlertPage',
    components: {DialogUpdateAlertComponent, AlertCardComponent, ToolbarBackButtonComponent},
    created() {
        this.userHasAccess('BASE', this.role_edit, true);
        this.getAlerts();
        this.getActions();
        this.dialog_alert = this.new_alert;
    },
    data() {
        return {
            switch_badge: true,
            switch_push: true,
            switch_sms: true,
            switch_email: true,

            tank: this.$store.getters['tanks/getTankById'](this.$route.params.id),
            headers: [
                {text: this.$t('manage_alert_page.alert'), value: 'name'},
                {text: this.$t('manage_alert_page.date'), value: 'last_activation'}
            ],

            search: '',

            loading_actions: false,
            actions: [],
            nb_item_show: 5,

            loading_alerts: false,
            measure_to_watch: [],
            type_measure: [
                {text: this.$t('manage_alert_page.limit'), value: 'limit'},
                {text: this.$t('manage_alert_page.list_alerts_setup'), value: 'variation'}
            ],

            is_new_alert: false,
            dialog_alert: {},

            search_alert: '',
            alerts_copy: [],
            alerts: [],

            new_alert: {
                id_device: this.$store.getters['tanks/getTankById'](this.$route.params.id).devices[0].id_device,
                label: "",

                user_preference: {
                    trigger_frequency: -1,
                    notification_badge: true,
                    notification_email: false,
                    notification_sms: false,
                    notification_push: false
                },

                trigger_frequency_default: -1,
                notification_default_badge: true,
                notification_default_email: false,
                notification_default_sms: false,
                notification_default_push: false,
                configuration: [
                    {
                        id_metric: null,
                        type: null,
                        operator: null,
                        limit: null,
                        unit: null,
                        variation_time: null,
                        time_unites_selected: null
                    }
                ],

                configuration_appearance: {
                    color_untriggered: "#65B265",
                    icon_untriggered: "access-point-remove theme",
                    color_triggered: "#DF5950",
                    icon_triggered: "wifi"
                }
            }
        }
    },
    watch: {
        search_alert: {
            handler() {
                if (!this.search_alert) {
                    this.alerts = this.alerts_copy;
                } else {
                    let alerts_local = [];
                    this.alerts_copy.forEach(alert => {
                        if (alert.name.includes(this.search_alert)) {
                            alerts_local.push(alert);
                        }
                    });

                    this.alerts = alerts_local;
                }
            },
            deep: true
        }
    },
    methods: {
        getAlerts() {
            this.loading_alerts = true;
            DeviceRepository.getAllAlertByIdDevice(this.tank.devices[0].id_device).then((success) => {
                this.alerts = success.data.data;
                this.alerts.forEach((alert, index) => {
                    this.alerts[index].is_user_subscribe = true;
                    if (alert.user_alert_preference === null) {
                        this.alerts[index].user_alert_preference = {
                            notification_badge: false,
                            notification_push: false,
                            notification_sms: false,
                            notification_email: false,
                            trigger_frequency: -1
                        }
                        this.alerts[index].is_user_subscribe = false;
                    }
                })
                this.alerts_copy = JSON.parse(JSON.stringify(this.alerts));

                //Set notifications switch
                if (this.alerts.filter(alert => alert.notification_badge).length === 0) {
                    this.switch_badge = false;
                }
                if (this.alerts.filter(alert => alert.notification_push).length === 0) {
                    this.switch_push = false;
                }
                if (this.alerts.filter(alert => alert.notification_sms).length === 0) {
                    this.switch_sms = false;
                }
                if (this.alerts.filter(alert => alert.notification_email).length === 0) {
                    this.switch_email = false;
                }
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.$refs.DialogUpdateAlertComponent.getMetrics(this.tank.devices[0].id_device);
                this.loading_alerts = false;
            });
        },

        getActions() {
            this.loading_actions = true;
            axios.get('https://8b6090ff-c5d2-4a92-a656-8fe7e45d0031.mock.pstmn.io/api/v1/tank/12345/alerts/actions').then((success) => {
                this.actions = success.data.data;
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading_actions = false;
            })
        },

        openDialogEditAlert(is_new_alert, alert = {}) {
            if (is_new_alert) {
                this.dialog_alert = JSON.parse(JSON.stringify(this.new_alert));
            } else {
                this.dialog_alert = JSON.parse(JSON.stringify(alert));
            }
            this.is_new_alert = is_new_alert;

            this.$refs.DialogUpdateAlertComponent.dialog_edit_alert = true;
        },

        manageDialogEdit(alert) {
            this.openDialogEditAlert(false, alert);
        },

        switchAlert(type) {
            switch (type) {
                case 'notification_default_badge':
                    this.alerts.forEach(alert => {
                        alert.notification_default_badge = this.switch_badge;
                    });
                    break;
                case 'notification_default_push':
                    this.alerts.forEach(alert => {
                        alert.notification_default_push = this.switch_push;
                    });
                    break;
                case 'notification_default_sms':
                    this.alerts.forEach(alert => {
                        alert.notification_default_sms = this.switch_sms;
                    });
                    break;
                case 'notification_default_email':
                    this.alerts.forEach(alert => {
                        alert.notification_default_email = this.switch_email;
                    });
                    break;
            }
            this.editAlert();
        },

        editAlert() {
            this.alerts.forEach(alert => {
                AlertRepository.updateAlert(alert.id_alert, alert).then(() => {
                    this.showSnackbar('success', this.$t('manage_alert_page.updated'));
                }).catch((error) => {
                    this.manageError(error);
                });
            });
        }
    }
}
</script>

<style>
.add-alert-btn {
    position: fixed !important;
    right: 16px !important;
    bottom: 16px !important;
}

fieldset {
    background: #f0f2f5 !important;
    border: none !important;
}

.low-height-datatable .v-data-table__mobile-row {
    min-height: 40px !important;
}
</style>